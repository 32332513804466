export const invoiceTypeOptions = [
    "Order no.",
    "HK Invoice no.",
    "USA Invoice no.",
    "Memo no.",
    "C.N. no.",
  ]

export const invoiceApiEndpointMap: {
  [name: string]: string
} = {
  "Order no.": "order-nos",
  "HK Invoice no.": "hk-invoice-nos",
  "USA Invoice no.": "usa-invoice-nos",
  "Memo no.": "memo-nos",
  "C.N. no.": "c-n-nos",
}