import { Config } from ".";

const config: Config = {
  strapiUrl: "https://zfuzopn5lukddsfl4vg5niwmve0ovphh.lambda-url.ap-east-1.on.aws/api",  
  invoiceDataTable: {
    idPlaces: 6
  },
};

export default config;
