import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MaterialReactTable,
} from "material-react-table";
import { FunctionComponent, useEffect, useState } from "react";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import config from "../../config";
import { zeroPad } from "./utils/zeroPad";
import qs from "qs";
import moment from "moment";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { invoiceApiEndpointMap, invoiceTypeOptions } from "../fixtures";

const queryClient = new QueryClient();

type InvoiceDataTableProps = {
  datatableUpdate: any;
};

export const InvoiceDataTable: FunctionComponent<InvoiceDataTableProps> = ({
  datatableUpdate,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <InvoiceDataTableInner datatableUpdate={ datatableUpdate } />
    </QueryClientProvider>
  );
};
const InvoiceDataTableInner: FunctionComponent<InvoiceDataTableProps> = ({
  datatableUpdate,
}) => {
  const [sessionToken, setSessionToken] = useState<string | null>(
    sessionStorage.getItem("jwt")
  );

  const [columns, setColumns] = useState([
    {
      accessorKey: "type",
      header: "Type",
    },
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "customerCode",
      header: "Customer Code",
    },
    {
      accessorKey: "sales",
      header: "Sales",
    },
    {
      accessorKey: "invoiceDate",
      header: "Invoice Date",
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
    },
  ]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: columns[5].accessorKey, desc: true },
  ]);

  const [queryString, setQueryString] = useState<any>({
    populate: {
      metadata: {
        populate: "*",
      },
    },
    sort: ["createdAt:desc"],
    pagination: {
      pageSize: 999999,
      page: 1,
    },
  });

  // useEffect(() => {
  //   setQueryString((prevState: any) => {
  //     const newSorting = sorting.map(item => `${item.id}:${item.desc ? "desc" : "asc"}`)
  //     return Object.assign({}, prevState, { sort: newSorting });
  //   })
  // }, [sorting])

  const [invoiceOrderNos, setInvoiceOrderNos] = useState<any>(null);
  const [invoiceCNnos, setInvoiceCNnos] = useState<any>(null);
  const [invoiceHKnos, setInvoiceHKnos] = useState<any>(null);
  const [invoiceUSAnos, setInvoiceUSAnos] = useState<any>(null);
  const [invoiceMemoNos, setInvoiceMemoNos] = useState<any>(null);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // useEffect(() => {
  //   setQueryString((prevState: any) => {
  //     const newPagination = { pageSize: pagination.pageSize, page: pagination.pageIndex + 1 };
  //     return Object.assign({}, prevState, { pagination: newPagination });
  //   })
  // }, [pagination])

  const [rowCount, setRowCount] = useState(0);

  const metadataTransformer = (d: any) => {
    if (d) {
      return {
        customerCode:
          d.attributes.metadata.customerCode.data?.attributes.customerCode,
        sales: d?.attributes.metadata.sales.data?.attributes.name,
        // invoiceDate: d.attributes.metadata.invoiceDate,
        invoiceDate: moment(d.attributes.metadata.invoiceDate).format("YYYY/MM/DD"),
        createdAt:
          moment(d.attributes.createdAt).format("YYYY/MM/DD hh:mm:ss A")
      };
    } else {
      return
    }
  };

  const getAllInvoiceData = async () => {
    const [
      invoiceOrderNos,
      invoiceCNnos,
      invoiceHKnos,
      invoiceUSAnos,
      invoiceMemoNos,
    ] = await Promise.all([
      getInvoiceOrderNos(),
      getInvoiceCNnos(),
      getInvoiceHKnos(),
      getInvoiceUSAnos(),
      getInvoiceMemoNos(),
    ]);

    const totalCount = [
      invoiceOrderNos.meta.pagination.total,
      invoiceCNnos.meta.pagination.total,
      invoiceHKnos.meta.pagination.total,
      invoiceUSAnos.meta.pagination.total,
      invoiceMemoNos.meta.pagination.total,
    ].reduce((a, b) => a + b, 0);

    const data = [
      ...invoiceOrderNos.data.map((d: any) => {
        return {
          type: invoiceTypeOptions[0],
          id: "PO-" + zeroPad(d.id, config.invoiceDataTable.idPlaces),
          data: d,
          ...metadataTransformer(d),
        };
      }),
      ...invoiceCNnos.data.map((d: any) => {
        return {
          type: invoiceTypeOptions[4],
          id: "ASCN-" + zeroPad(d.id, config.invoiceDataTable.idPlaces),
          data: d,
          ...metadataTransformer(d),
        };
      }),
      ...invoiceHKnos.data.map((d: any) => {
        return {
          type: invoiceTypeOptions[1],
          id: "AS-" + zeroPad(d.id, config.invoiceDataTable.idPlaces),
          data: d,
          ...metadataTransformer(d),
        };
      }),
      ...invoiceUSAnos.data.map((d: any) => {
        return {
          type: invoiceTypeOptions[2],
          id: "USA-" + zeroPad(d.id, config.invoiceDataTable.idPlaces),
          data: d,
          ...metadataTransformer(d),
        };
      }),
      ...invoiceMemoNos.data.map((d: any) => {
        return {
          type: invoiceTypeOptions[3],
          id: "ASM-" + zeroPad(d.id, config.invoiceDataTable.idPlaces),
          data: d,
          ...metadataTransformer(d),
        };
      }),
    ];

    console.log("data", data);

    const json = {
      data: data,
      meta: {
        pagination: {
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          total: totalCount,
        },
      },
    };

    setRowCount(totalCount);
    return json;
  };

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      // columnFilters, //refetch when columnFilters changes
      // globalFilter, //refetch when globalFilter changes
      // pagination.pageIndex, //refetch when pagination.pageIndex changes
      // pagination.pageSize, //refetch when pagination.pageSize changes
      // sorting, //refetch when sorting changes
    ],
    queryFn: getAllInvoiceData,
  });

  useEffect(() => {
    refetch();
    console.log("datatableUpdate", datatableUpdate);
  }, [datatableUpdate]);

  const getInvoiceOrderNos = async () => {
    const result = await fetch(
      `${config.strapiUrl}/order-nos?${qs.stringify(queryString)}`,
      {
        headers: { Authorization: "Bearer " + sessionToken },
      }
    );
    const json = await result.json();
    console.log("getInvoiceOrderNos result", json);
    setInvoiceOrderNos(json);
    return json;
  };

  const getInvoiceCNnos = async () => {
    const result = await fetch(
      `${config.strapiUrl}/c-n-nos?${qs.stringify(queryString)}`,
      {
        headers: { Authorization: "Bearer " + sessionToken },
      }
    );
    const json = await result.json();
    console.log("getInvoiceCNnos result", json);
    setInvoiceCNnos(json);
    return json;
  };

  const getInvoiceHKnos = async () => {
    const result = await fetch(
      `${config.strapiUrl}/hk-invoice-nos?${qs.stringify(queryString)}`,
      {
        headers: { Authorization: "Bearer " + sessionToken },
      }
    );
    const json = await result.json();
    console.log("getInvoiceHKnos result", json);
    setInvoiceHKnos(json);
    return json;
  };

  const getInvoiceUSAnos = async () => {
    const result = await fetch(
      `${config.strapiUrl}/usa-invoice-nos?${qs.stringify(queryString)}`,
      {
        headers: { Authorization: "Bearer " + sessionToken },
      }
    );
    const json = await result.json();
    console.log("setInvoiceUSAnos result", json);
    setInvoiceUSAnos(json);
    return json;
  };

  const getInvoiceMemoNos = async () => {
    const result = await fetch(
      `${config.strapiUrl}/memo-nos?${qs.stringify(queryString)}`,
      {
        headers: { Authorization: "Bearer " + sessionToken },
      }
    );
    const json = await result.json();
    console.log("getInvoiceMemoNos result", json);
    setInvoiceMemoNos(json);
    return json;
  };

  const handleRowDelete = async (e: any, row: any) => {
    console.info("Delete", row);
    const colletion = invoiceApiEndpointMap[row.original.type];
    const id = row.original.data.id;
    const result = await fetch(`${config.strapiUrl}/${colletion}/${id}`, {
      method: "DELETE",
      headers: { Authorization: "Bearer " + sessionToken },
    });
    refetch();
  };

  const csvOptions = {
    filename: "invoice-data",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = () => {

    const cleanData = [];
    for (const row of data?.data ?? []) {
      const { data, ...rest } = row;
      cleanData.push(rest);
    }

    csvExporter.generateCsv(cleanData);
  };

  return (
    <MaterialReactTable
      muiTablePaperProps={{
        sx: {
          width: "100%",
        },
      }}
      columns={columns}
      data={data?.data ?? []}
      enableRowActions
      renderRowActions={({ row }) => (
        <Box>
          <IconButton onClick={(e) => handleRowDelete(e, row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
      // enableRowSelection
      // getRowId={(row) => row.phoneNumber}
      initialState={{
        sorting: [{ id: columns[5].accessorKey, desc: true }],
      }}
      // manualFiltering
      // manualPagination
      // manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
            color: "error",
            children: "Error loading data",
          }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      renderTopToolbarCustomActions={(table) => (
        <>
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export All Data
            </Button>
          </Box>
        </>
      )}
      rowCount={rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching,
        sorting,
      }}
    />
  );
};
