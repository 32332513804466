import { FunctionComponent, useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  TextField,
  Icon,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import styles from "./MainPage.module.css";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import config from "../config";
import { InvoiceDataTable } from "../components/InoviceDataTable"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

import { invoiceApiEndpointMap, invoiceTypeOptions } from "../components/fixtures"
import { useMutation, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LoadingButton from '@mui/lab/LoadingButton';


const filter = createFilterOptions<any>();


const queryClient = new QueryClient()


const MainPage: FunctionComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MainPageInner />
    </QueryClientProvider>
  )
}

const MainPageInner: FunctionComponent = () => {
  const [datePickerDateTimePickerValue, setDatePickerDateTimePickerValue] = useState<Date | null>(new Date());
  const [sessionToken, setSessionToken] = useState<string | null>(sessionStorage.getItem("jwt"));

  const [invoiceType, setInvoiceType] = useState(invoiceTypeOptions[0]);

  const [sales, setSales] = useState<any>(null)
  const [salesOptions, setSalesOptions] = useState<any>(null)
  const [customerCode, setCustomerCode] = useState<any>("")
  const [customerCodeOptions, setCustomerCodeOptions] = useState<any>(null)
  const [datatableUpdate, setDatatableUpdate] = useState<number>(0)
  const navigate = useNavigate();

  // Customer Code requests
  const { mutateAsync: addCustomerCode, isLoading: isCustomerCodeLoading } = useMutation({
    mutationFn: async (newCustomerCode: any) => {
      const foundCustomerCode = customerCodeOptions.data.find((d: any) => d.attributes.customerCode === newCustomerCode)
      // Check if customer code already exists
      if (!foundCustomerCode) {
        // If customer code does not exist, create a new entry
        return fetch(`${config.strapiUrl}/customer-codes`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + sessionToken,
          },
          body: JSON.stringify({
            data: {
              customerCode: newCustomerCode,
            }
          }),
        }).then((response) => {
          return response.json()
        });
      } else {
        return { data: foundCustomerCode };
      }
    },
    onSuccess: async () => {
      await getCustomerCodes();
    }
  })

  const invoiceSubmitEndpoint: string = invoiceApiEndpointMap[invoiceType];

  const { mutateAsync: addInvoice, isLoading: isInvoiceLoading } = useMutation({
    mutationFn: async (newInvoicePayload: any) => {
      return fetch(`${config.strapiUrl}/${invoiceSubmitEndpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionToken,
        },
        body: JSON.stringify(newInvoicePayload),
      });
    },
  })


  useEffect(() => {

    // Session Token
    const onSessionTokenUpdate = () => {
      const jwt = sessionStorage.getItem("jwt");
      (jwt) ?
        setSessionToken(jwt)
        : navigate("/");
    }
    window.addEventListener('storage', onSessionTokenUpdate);

    // sales and invoice data fetch
    getSales()
    getCustomerCodes()

    console.log("useEffect [] updated");

    return () => {
      window.removeEventListener('storage', onSessionTokenUpdate);
    };
  }, []);

  const getSales = async () => {
    const result = await fetch(`${config.strapiUrl}/sales`, {
      headers: { Authorization: "Bearer " + sessionToken },
    });
    console.log("getSales result", result);
    const json = await result.json();
    setSalesOptions(json);
  };

  const getCustomerCodes = async () => {
    const result = await fetch(`${config.strapiUrl}/customer-codes`, {
      headers: { Authorization: "Bearer " + sessionToken },
    })
    const json = await result.json()
    console.log("getCustomerCodes result", json)
    setCustomerCodeOptions(json)
    return json
  }

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("customerCode", customerCode);

    try {

      const payload = {
        "data": {
          "metadata": {
            "customerCode": customerCode.id,
            "sales": sales.id,
            "invoiceDate": moment(datePickerDateTimePickerValue).format()
          }
        }
      }

      if (!customerCode.id) {
        const newCustomerCode = await addCustomerCode(customerCode.attributes.customerCode);
        console.log("newCustomerCode", newCustomerCode);
        payload.data.metadata.customerCode = newCustomerCode.data.id
        setCustomerCode(newCustomerCode.data);
        console.log("newpayload", payload);
      }
      await addInvoice(payload);
      // await submitInvoice(newCustomerCode?.data?.id);
      toast.success('🦄 Wow so easy!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDatatableUpdate(n => n + 1);
    } catch (error: any) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }



    // onSuccess() {
    //   setDatatableUpdate(n => n + 1);
    //   },


  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={styles.mainPage}>
        <div className={styles.image2Parent}>
          <img className={styles.image2Icon} alt="" src="/image-2@2x.png" />
          <div className={styles.welcomeWilsonParent}>
            <div className={styles.welcomeWilson}>Welcome Wilson,</div>
            <div className={styles.frameParent}>
              <form onSubmit={handleFormSubmit}>
                <div className={styles.selectstandardParent}>

                  <FormControl sx={{ width: 220 }} variant="standard" id="sales" required>
                    <InputLabel color="primary">Sales</InputLabel>
                    <Select
                      color="primary"
                      name="sales"
                      id="sales"
                      size="small"
                      label="Sales"
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;
                        console.log("set sales value", value);
                        setSales(value);
                      }}
                    >
                      {
                        salesOptions ? salesOptions.data.map((d: any, i: number) => <MenuItem key={i} value={d}>{d.attributes.name}</MenuItem>) : undefined
                      }
                    </Select>
                    <FormHelperText />
                  </FormControl>
                  <FormControl
                    sx={{ width: 220 }}
                    variant="standard"
                    required
                    id="invoicetype"
                  >
                    <InputLabel color="primary">Invoice Type</InputLabel>
                    <Select
                      color="primary"
                      name="invoice type"
                      id="invoicetype"
                      size="small"
                      label="Invoice Type"
                      value={invoiceType}
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;
                        setInvoiceType(value);
                      }}
                    >
                      {
                        invoiceTypeOptions.map((opt: any, i: number) => <MenuItem key={i} value={opt}>{opt}</MenuItem>)
                      }
                    </Select>
                    <FormHelperText />
                  </FormControl>
                  <FormControl
                    sx={{ width: 220 }}
                    variant="standard"
                    id="customerCode"
                    required
                  >
                    <Autocomplete
                      className={styles.inputstandard}
                      id="customercode"
                      sx={{ width: 220 }}
                      color="primary"
                      defaultValue=""
                      size="small"
                      freeSolo
                      options={customerCodeOptions?.data || []}
                      // filterOptions={(options, params) => {
                      //   const filtered = filter(options, params);

                      //   const { inputValue } = params;
                      //   // Suggest the creation of a new value
                      //   const isExisting = options.some((option: any) => inputValue === option || inputValue === option.attributes.customerCode);
                      //   if (inputValue !== '' && !isExisting) {
                      //     filtered.push({
                      //       inputValue: inputValue,
                      //       attributes: {
                      //         customerCode: `Add "${inputValue}"`
                      //       }
                      //     });
                      //   }

                      //   return filtered;
                      // }}
                      // onChange={(event: any, newValue: any) => {
                      //   console.log("customerCode autocomplete newValue", newValue)
                      //   if (typeof newValue === 'string') {
                      //     setCustomerCode(newValue);
                      //   } else if (newValue && newValue.inputValue) {
                      //     console.log("Create a new value from the user input", newValue.inputValue)
                      //     // Create a new value from the user input
                      //     setCustomerCode({ id: null, attributes: { customerCode: newValue.inputValue } });
                      //   } else {
                      //     setCustomerCode(newValue);
                      //   }
                      // }}
                      onChange={(event: any, newValue: any) => {
                        console.log("no new code", newValue)
                        setCustomerCode(newValue);
                      }}
                      onInputChange={(event: any, newInputValue: any) => {
                        console.log("customerCode newInputValue", newInputValue)
                        if (typeof newInputValue === 'string') {
                          setCustomerCode({ id: null, attributes: { customerCode: newInputValue } });
                        } else if (newInputValue && newInputValue.inputValue) {
                          console.log("Create a new value from the user input", newInputValue)
                          // Create a new value from the user input
                          setCustomerCode({ id: null, attributes: { customerCode: newInputValue } });
                        } else {
                          setCustomerCode(newInputValue);
                        }
                      }}
                      getOptionLabel={(option: any) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.attributes.customerCode;
                      }}
                      renderOption={(props, option: any) => <li {...props}>{option.attributes.customerCode}</li>}
                      renderInput={(params) => <TextField {...params} label="Customer Code" variant="standard" required />}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ width: 220 }}
                    variant="standard"
                    id="customerCode"
                    required
                  >
                    <DatePicker
                      label="Invoice Date"
                      value={datePickerDateTimePickerValue}
                      onChange={(newValue: any) => {
                        setDatePickerDateTimePickerValue(newValue);
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          name="invoicedate"
                          id="invoicedate"
                          color="primary"
                          variant="standard"
                          size="small"
                          helperText=""
                          required
                        />
                      )}
                    />
                  </FormControl>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    name="Generate Btn"
                    id="generatebtn"
                    color="primary"
                    size="small"
                    loading={isCustomerCodeLoading || isInvoiceLoading}
                  >
                    GENERATE
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.recordsParent}>
            <div className={styles.welcomeWilson}>Records</div>
            <div className={styles.frameChild} />
          </div>
          <InvoiceDataTable datatableUpdate={datatableUpdate} />
        </div>
      </div>
    </LocalizationProvider>
  );
};

// const Sidebar:  = 

export default MainPage;

