import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";
import {
  LoadingButton
} from "@mui/lab";
import { useNavigate } from "react-router-dom";
import styles from "./LoginPage.module.css";
import config from "../config";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';

const queryClient = new QueryClient();

const LoginPage: FunctionComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <LoginPageInner />
    </QueryClientProvider>
  )
}

const LoginPageInner: FunctionComponent = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const jwt = sessionStorage.getItem("jwt");

    if (jwt) {
      const currentTime = Date.now() / 1000; // Get the current time in seconds
      const { exp } = JSON.parse(atob(jwt.split(".")[1])); // Destructure the exp property

      if (currentTime > exp) {
        sessionStorage.removeItem("jwt"); // Remove the JWT token
        console.log("JWT expired");
      } else {
        console.log("JWT found");
        navigate("/main-page");
      }
    } else {
      console.log("No JWT found");
    }
  }, []);

  const { mutate: doLogin, isLoading, error } = useMutation(loginResuest, {
    onSuccess: (data: any) => {
      sessionStorage.setItem("jwt", data.jwt);
      navigate("/main-page");
    },
    onError: (error: any) => {
      return toast.error(error.message);
    },
  });


  async function loginResuest({ username, password }: { username: String, password: String }): Promise<Response> {
    const response = await fetch(`${config.strapiUrl}/auth/local`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify({
        identifier: username,
        password: password
      })
    })
    if (!response.ok) {
      throw new Error('Failed to login');
    }
    return await response.json();
  }

  const onButtonTextAndIconClick = () => {
    doLogin({ username, password })
  };

  return (
    <div className={styles.loginPage}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover={true}
        draggable={false}
        theme="light"
      />
      <div className={styles.image2Parent}>
        <img className={styles.image2Icon} alt="" src="/image-21@2x.png" />
        <div className={styles.frameWrapper}>
          <div className={styles.frameParent}>
            <div className={styles.invoiceNumberGeneratorV101Wrapper}>
              <div className={styles.invoiceNumberGenerator}>
                Invoice Number Generator v1.01
              </div>
            </div>
            <div className={styles.inputoutlinedParent}>
              <TextField
                className={styles.inputoutlined}
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                sx={{ width: 220 }}
                color="primary"
                variant="outlined"
                type="text"
                name="User Name"
                id="username"
                label="User Name"
                placeholder="username"
                size="small"
                margin="none"
                required
              />
              <TextField
                className={styles.inputoutlined}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ width: 220 }}
                color="primary"
                variant="outlined"
                type="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility">
                        <Icon>visibility</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="Password"
                id="password"
                label="Password"
                size="small"
                margin="none"
                required
              />
              <LoadingButton
                loading={isLoading}
                loadingIndicator="Logging In..."
                className={styles.buttontextAndIcon}
                variant="text"
                name="LoginBtn"
                id="loginbtn"
                color="primary"
                size="small"
                endIcon={<Icon>arrow_forward_sharp</Icon>}
                onClick={onButtonTextAndIconClick}
              >
                Continue
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
