import development from "./development";
import production from "./production";
import local from "./local";

export interface Config {
  strapiUrl: string;
  invoiceDataTable: {
    idPlaces: number
  };
}

export const isInLambda = !!process.env.LAMBDA_TASK_ROOT;
const defaultEnv = isInLambda ? "production" : "local";

export const env = process.env.REACT_APP_ENVIRONMENT || defaultEnv;

const config: Config =
  env === "production"
    ? production
    : env === "development"
    ? development
    : local;

console.log("env", env);
console.log("config", config);

export default config;
